import Client_Infocenter_Who_We_Are_1 from "../../images/Client_Infocenter_Who_We_Are_1.png";
import Client_Infocenter_Specialized_Talent_Solutions_1 from "../../images/Client_Infocenter_Specialized_Talent_Solutions_1.png";
import Recruitment_Expertise_1 from "../../images/Recruitment_Expertise_1.png";
var aboutTandymLink = function () {
    var url = "https://tandymgroup.com/about/";
    var windowFeatures = "width=500,height=350,left=500,top=200";
    window.open(url, "_blank", windowFeatures);
};
export var aboutTandymContent = [
    {
        title: "Who We Are?",
        image: Client_Infocenter_Who_We_Are_1,
        description: "Since 1985, Tandym Group has evolved from a niche recruitment firm to a comprehensive workforce solutions provider, maintaining our core values of personalized service and expertise while expanding nationwide across industries. Our human-centered approach is enhanced by best-in-class AI, delivering superior insights and connections to drive businesses and careers forward.",
        buttonLabel: "Learn More",
        onClickBtn: function () {
            aboutTandymLink();
        },
    },
    {
        title: "Specialized Talent Solutions",
        image: Client_Infocenter_Specialized_Talent_Solutions_1,
        description: "We\u2019re proven partners who address mission-critical initiatives with flexible, adaptable solutions and a deep network of talented, committed healthcare, technology, and business professionals.",
        buttonLabel: "Learn More",
        onClickBtn: function () {
            aboutTandymLink();
        },
    },
    {
        title: "Recruitment Expertise",
        image: Recruitment_Expertise_1,
        description: "We specialize in staffing and recruitment, workforce solutions, and managed solutions to meet the needs across all organizations in a multitude of industries. We have a deep understanding of each client\u2019s needs, and mentorship of candidates to deliver seamless fits between talent and roles for long-lasting solutions.",
        buttonLabel: "Learn More",
        onClickBtn: function () {
            aboutTandymLink();
        },
    },
];
