import { colorCodes } from "../../../../tandym-web-common/src/shared/components/styles/ColorCodesList";
export var InfoCenterStyles = {
    contact: {
        marginTop: "50px",
    },
    contactText: {
        color: colorCodes.backgroundColors.white,
        fontWeight: 400,
    },
    contactInfo: {
        color: colorCodes.backgroundColors.white,
        fontWeight: 300,
        marginTop: "14px",
    },
    contactImg: {
        backgroundSize: "cover",
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
        position: "relative",
    },
    contactImgContent: {
        position: "absolute",
        paddingTop: "50px",
        paddingLeft: "40px",
    },
    generalContactSection: {
        background: colorCodes.backgroundColors.alabaster,
        display: "flex",
        flexDirection: "column",
    },
    cardText: {
        color: colorCodes.textColors.woodSmock,
        fontWeight: 400,
    },
    cardSection: {
        padding: "34px 23px",
        border: "1px solid ".concat(colorCodes.cardColors.borderLightGray),
        flexGrow: 1,
    },
};
