var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TPStyledModal } from './OperationalMetrics.styled';
import { Box, Typography } from '@mui/material';
import TPButton from '../../../../../tandym-web-common/src/shared/components/button/TPButton';
var InfoModal = function (_a) {
    var infoModalData = _a.infoModalData, setOpenInfoModal = _a.setOpenInfoModal;
    return (_jsxs(TPStyledModal, { children: [_jsx(Box, { children: infoModalData.map(function (item, index) { return (_jsxs(Box, __assign({ mb: 2 }, { children: [_jsx(Typography, __assign({ variant: 'h6' }, { children: item.title })), _jsx(Typography, __assign({ variant: 'body1' }, { children: item.description })), item.itemsList && (_jsx(Box, { children: _jsx("ul", { children: item.itemsList.map(function (listItem, index) { return (_jsx("li", { children: listItem }, index)); }) }) }))] }), index)); }) }), _jsx(Box, __assign({ mt: 3 }, { children: _jsx(TPButton, __assign({ variant: 'contained', color: 'primary', onClick: function () { return setOpenInfoModal(false); } }, { children: "Close" })) }))] }));
};
export default InfoModal;
