import styled from 'styled-components';
import { Box } from '@mui/material';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
export var FillRateBoxStyled = styled(Box)({
    '.responsive-image': {
        width: '100%',
        height: 'auto',
        maxWidth: '150px',
    },
    '.no-data-text1': {
        color: '#34343F',
        fontSize: fontSizeDynamic(20),
        fontWeight: 400,
        margin: '10px 0',
    },
    '.no-data-text2': {
        color: '#464665',
        fontSize: fontSizeDynamic(15),
        fontWeight: 400,
    },
});
