var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TPSvgIcon from '../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { homeTabStyles } from '../Home.style';
import { InfoIconCarousel, overViewStyles } from './OverViewStyles';
import { colorCodes } from '../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import { AvailableTandymPositions, LatestTandymVacancyTile, PopupCarouselCard, Tile, } from './TilesComponent';
import CustomModal from '../../../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import TPCarousel from '../../../../../tandym-web-common/src/shared/components/carousel/TPCarousel';
import { SectionTitle, solutions } from '../../../shared/utilities/enumValues';
import { functionalGroupName } from '../../../shared/utilities/utilities';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var LiveContractorsAndOpenPositionsContainer = function (_a) {
    var id = _a.id, title = _a.title, bannerImage = _a.bannerImage, totalLiveContractorData = _a.totalLiveContractorData, circleColorCodes = _a.circleColorCodes, liveContractorList = _a.liveContractorList, activePositionList = _a.activePositionList, availableTandymPositionList = _a.availableTandymPositionList, openPositions = _a.openPositions, headerIcon = _a.headerIcon, topTenVacanciesList = _a.topTenVacanciesList, onArrowClick = _a.onArrowClick;
    var _b = useState(false), latestTandymOpeningIIcon = _b[0], setLatestTandymOpeningIIcon = _b[1];
    var _c = useState(''), topTenVacancyDrillDownFnGrpName = _c[0], setTopTenVacancyDrillDownFnGrpName = _c[1];
    var dataList = title === SectionTitle.MyLiveContractors
        ? liveContractorList
        : activePositionList;
    var getConsultingSolutions = function (data) {
        return data.filter(function (item) { return item.solution === 'Consulting Solutions'; });
    };
    var getMissionCoreSolutions = function (data) {
        return data.filter(function (item) { return item.solution === 'Mission Core Solutions'; });
    };
    var getTileDrilldownList = function (data, name) {
        return data.filter(function (item) { return item.name === name; });
    };
    var handleCardClick = function (item) {
        if (item) {
            var mailtoLink = "mailto:clientsupport@tandymgroup.com?subject=Job Opportunity: ".concat(item === null || item === void 0 ? void 0 : item.jobTitle, "&body=Industry: ").concat(item === null || item === void 0 ? void 0 : item.industry, "%0D%0ALocation: ").concat(item === null || item === void 0 ? void 0 : item.location, "%0D%0AJob Opportunity: ").concat(item === null || item === void 0 ? void 0 : item.jobTitle);
            window.location.href = mailtoLink;
        }
    };
    var topTenVacanciesClickHandler = function (item) {
        setLatestTandymOpeningIIcon(true);
        setTopTenVacancyDrillDownFnGrpName(item);
    };
    var isLiveContractorsAvailable = liveContractorList && (liveContractorList === null || liveContractorList === void 0 ? void 0 : liveContractorList.length) > 0;
    var isactivePositionListAvailable = activePositionList && (activePositionList === null || activePositionList === void 0 ? void 0 : activePositionList.length) > 0;
    return (_jsxs(Grid, __assign({ item: true, xs: 12, sx: homeTabStyles.ContentBoxStyle }, { children: [_jsx(Box, __assign({ ml: 1, sx: [
                    overViewStyles.root,
                    overViewStyles.image,
                    {
                        height: window.innerWidth > 1800
                            ? "".concat((window.innerWidth * 10) / 70, "px")
                            : 'auto',
                        width: window.innerWidth > 1800
                            ? "".concat((window.innerWidth * 10) / 14.8, "px")
                            : 'auto',
                        backgroundImage: window.innerWidth < 900 ? 'none' : "url(".concat(bannerImage, ")"),
                        borderBottom: window.innerWidth >= 1400
                            ? "1px solid ".concat(colorCodes.borderColor.QuillGray)
                            : 'none',
                    },
                ] }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(Box, __assign({ sx: [overViewStyles.root, { mt: window.innerWidth < 900 ? 1 : 2 }] }, { children: [_jsx(TPSvgIcon, { id: "".concat(title, "-liv-ctr-opn-position-").concat(id, "-icon"), "data-testid": "".concat(title, "-liv-ctr-opn-position-").concat(id, "-icon"), resourceName: headerIcon ? headerIcon : '' }), _jsx(Typography, __assign({ id: "contentBox-".concat(id, "-title"), sx: homeTabStyles.titleStyle }, { children: title })), (isLiveContractorsAvailable || isactivePositionListAvailable) && (_jsx(Box, __assign({ id: "".concat(title, "-liv-ctr-opn-position-arrow-btn-").concat(id), "data-testid": "".concat(title, "-liv-ctr-opn-position-arrow-btn-").concat(id), sx: [overViewStyles.hoverEffect, { ml: 2 }], onClick: function () {
                                        return onArrowClick(title, title === SectionTitle.MyLiveContractors
                                            ? liveContractorList
                                            : activePositionList);
                                    } }, { children: _jsx(TPSvgIcon, { id: "".concat(title, "-liv-ctr-opn-position-arrow-").concat(id, "-icon"), "data-testid": "".concat(title, "-liv-ctr-opn-position-arrow-").concat(id, "-icon"), resourceName: 'arrow-icon-right' }) })))] })), _jsx(Box, __assign({ sx: overViewStyles.root }, { children: totalLiveContractorData &&
                                (totalLiveContractorData === null || totalLiveContractorData === void 0 ? void 0 : totalLiveContractorData.map(function (item, index) { return (_jsxs(Box, __assign({ sx: [
                                        overViewStyles.liveContractorTitleBox,
                                        {
                                            pl: index > 0 ? 4 : 0,
                                            borderRight: (window.innerWidth < 900 ? index < 3 : index < 2)
                                                ? "1px solid ".concat(colorCodes.borderColor.QuillGray)
                                                : 'none',
                                        },
                                    ] }, { children: [_jsx(Typography, __assign({ id: "".concat(item.title, "-liv-ctr-opn-position-card-title-").concat(id), "data-testid": "".concat(item.title, "-liv-ctr-opn-position-card-title-").concat(id), sx: [overViewStyles.text, { pr: 3 }] }, { children: item === null || item === void 0 ? void 0 : item.title })), _jsxs(Box, __assign({ id: "".concat(item === null || item === void 0 ? void 0 : item.title, "-liv-ctr-opn-position-card-count-").concat(id), "data-testid": "".concat(item === null || item === void 0 ? void 0 : item.title, "-liv-ctr-opn-position-card-count-").concat(id), mt: 1, gap: 1, sx: overViewStyles.root }, { children: [_jsx(Typography, __assign({ id: "".concat(item.title, "-liv-ctr-opn-position-card-total-count-").concat(id), "data-testid": "".concat(item === null || item === void 0 ? void 0 : item.title, "-liv-ctr-opn-position-total-card-count-").concat(id), sx: overViewStyles.number }, { children: item === null || item === void 0 ? void 0 : item.totalCount })), (isLiveContractorsAvailable ||
                                                    isactivePositionListAvailable) && (_jsx(Box, __assign({ sx: overViewStyles.hoverEffect, onClick: function () {
                                                        return onArrowClick(item === null || item === void 0 ? void 0 : item.title, title === SectionTitle.MyLiveContractors
                                                            ? (item === null || item === void 0 ? void 0 : item.title) === 'Total My Live Contractors'
                                                                ? liveContractorList
                                                                : (item === null || item === void 0 ? void 0 : item.title) === 'Consulting Solutions'
                                                                    ? getConsultingSolutions(liveContractorList)
                                                                    : getMissionCoreSolutions(liveContractorList)
                                                            : (item === null || item === void 0 ? void 0 : item.title) === 'Total My Open Jobs'
                                                                ? activePositionList
                                                                : (item === null || item === void 0 ? void 0 : item.title) === 'Consulting Solutions'
                                                                    ? getConsultingSolutions(activePositionList)
                                                                    : getMissionCoreSolutions(activePositionList));
                                                    } }, { children: _jsx(TPSvgIcon, { id: "".concat(title, "-liv-ctr-opn-position-arrow-for-live-contractors-icon-").concat(id), "data-testid": "".concat(title, "-liv-ctr-opn-position-arrow-for-live-contractors-icon-").concat(id), resourceName: 'arrow-for-live-contractors' }) })))] }))] }), index + (item === null || item === void 0 ? void 0 : item.title))); })) }))] })) })), window.innerWidth < 900 && (_jsx(Box, { sx: [overViewStyles.imageBorderBottom] })), _jsxs(Box, { children: [_jsxs(Box, __assign({ sx: [overViewStyles.root, overViewStyles.consultingSolutionsBox] }, { children: [_jsx(Typography, __assign({ id: "".concat(title, "-liv-ctr-opn-position-box-header-text-").concat(id), "data-testid": "".concat(title, "-liv-ctr-opn-position-box-header-text-").concat(id), sx: overViewStyles.consultingSolution }, { children: "Consulting Solutions" })), _jsx(Box, __assign({ gap: 4, sx: overViewStyles.root }, { children: circleColorCodes &&
                                    (circleColorCodes === null || circleColorCodes === void 0 ? void 0 : circleColorCodes.map(function (item, index) { return (_jsxs(Box, __assign({ gap: 1, sx: overViewStyles.root }, { children: [_jsx(Box, { sx: [
                                                    overViewStyles.colorIdentifier,
                                                    {
                                                        background: item.color ? item.color : null,
                                                    },
                                                ] }), _jsx(Typography, __assign({ id: "".concat(title, "-liv-ctr-opn-position-").concat(item.text, "-").concat(id), "data-testid": "".concat(title, "-liv-ctr-opn-position-").concat(item.text, "-").concat(id), sx: overViewStyles.colorCircle }, { children: item === null || item === void 0 ? void 0 : item.text }))] }), index + (item === null || item === void 0 ? void 0 : item.text))); })) }))] })), _jsxs(Box, __assign({ id: "".concat(title, "-liv-ctr-opn-position-tile-container-").concat(id), sx: overViewStyles.tileContainer }, { children: [(dataList === null || dataList === void 0 ? void 0 : dataList.map(function (item) { return item.solution === solutions.ConsultingSolutions; }).length) === 0 ? (_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16), pl: 1 }, { children: "No Consulting Solutions Available" }))) : (dataList === null || dataList === void 0 ? void 0 : dataList.map(function (item) {
                                var _a;
                                return (title === SectionTitle.MyOpenPositions ||
                                    title === SectionTitle.MyLiveContractors) &&
                                    (item === null || item === void 0 ? void 0 : item.solution) === solutions.ConsultingSolutions && (_jsx(Box, { children: (item === null || item === void 0 ? void 0 : item.positionCount) === 0 &&
                                        title === SectionTitle.MyOpenPositions ? (_jsx(LatestTandymVacancyTile, { id: item === null || item === void 0 ? void 0 : item.id, title: item === null || item === void 0 ? void 0 : item.name, count: item === null || item === void 0 ? void 0 : item.positionCount, sector: item === null || item === void 0 ? void 0 : item.sector, tandymOpening: (_a = item === null || item === void 0 ? void 0 : item.drillDownData[0]) === null || _a === void 0 ? void 0 : _a.jobTitle, onClick: function () { return topTenVacanciesClickHandler(item === null || item === void 0 ? void 0 : item.name); } })) : (_jsx(Tile, { id: item === null || item === void 0 ? void 0 : item.id, title: item === null || item === void 0 ? void 0 : item.name, count: item === null || item === void 0 ? void 0 : item.positionCount, sector: item === null || item === void 0 ? void 0 : item.sector, openPositions: openPositions, onArrowClick: function () {
                                            return onArrowClick(item === null || item === void 0 ? void 0 : item.name, getTileDrilldownList(dataList, item === null || item === void 0 ? void 0 : item.name));
                                        } })) }, item === null || item === void 0 ? void 0 : item.id));
                            })), title === SectionTitle.MyOpenPositions &&
                                availableTandymPositionList &&
                                (availableTandymPositionList === null || availableTandymPositionList === void 0 ? void 0 : availableTandymPositionList.map(function (item, index) {
                                    return item.solution === solutions.ConsultingSolutions && (_jsxs(Box, __assign({ sx: {
                                            width: '30%',
                                            p: 1,
                                        } }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16) }, { children: "Available Tandym Jobs" })), _jsx(Box, __assign({ sx: [
                                                    overViewStyles.root,
                                                    { display: 'flex', flexWrap: 'wrap' },
                                                ] }, { children: item === null || item === void 0 ? void 0 : item.availableTandymPosition.map(function (item, index) { return (_jsx(Box, __assign({ p: 1 }, { children: _jsx(AvailableTandymPositions, { id: index, sector: item === null || item === void 0 ? void 0 : item.sector, functionalGroupName: item === null || item === void 0 ? void 0 : item.functionalGroupName, positionCount: item === null || item === void 0 ? void 0 : item.count }) }), index + (item === null || item === void 0 ? void 0 : item.functionalGroupName))); }) }))] }), index +
                                        SectionTitle.MyOpenPositions +
                                        solutions.ConsultingSolutions));
                                }))] }))] }), _jsx(Box, __assign({ mt: 3, ml: 1 }, { children: _jsx(Typography, __assign({ id: "".concat(title, "-liv-ctr-opn-position-mission-core-").concat(id), sx: overViewStyles.consultingSolution }, { children: "Mission Core Solutions" })) })), _jsxs(Box, __assign({ sx: overViewStyles.tileContainer }, { children: [(dataList === null || dataList === void 0 ? void 0 : dataList.map(function (item) { return item.solution === solutions.MissionCoreSolutions; }).length) === 0 ? (_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16), pl: 1 }, { children: "No Mission Core Solutions Available" }))) : (dataList === null || dataList === void 0 ? void 0 : dataList.map(function (item) {
                        var _a;
                        return (title === SectionTitle.MyOpenPositions ||
                            title === SectionTitle.MyLiveContractors) &&
                            (item === null || item === void 0 ? void 0 : item.solution) === solutions.MissionCoreSolutions && (_jsx(Box, { children: (item === null || item === void 0 ? void 0 : item.positionCount) === 0 &&
                                title === SectionTitle.MyOpenPositions ? (_jsx(LatestTandymVacancyTile, { id: item === null || item === void 0 ? void 0 : item.id, title: item === null || item === void 0 ? void 0 : item.name, count: item === null || item === void 0 ? void 0 : item.positionCount, sector: item === null || item === void 0 ? void 0 : item.sector, tandymOpening: (_a = item === null || item === void 0 ? void 0 : item.drillDownData[0]) === null || _a === void 0 ? void 0 : _a.jobTitle, onClick: function () { return topTenVacanciesClickHandler(item === null || item === void 0 ? void 0 : item.name); } })) : (_jsx(Tile, { id: item === null || item === void 0 ? void 0 : item.id, title: item === null || item === void 0 ? void 0 : item.name, count: item === null || item === void 0 ? void 0 : item.positionCount, sector: item === null || item === void 0 ? void 0 : item.sector, openPositions: openPositions, onArrowClick: function () {
                                    return onArrowClick(item === null || item === void 0 ? void 0 : item.name, getTileDrilldownList(dataList, item === null || item === void 0 ? void 0 : item.name));
                                } })) }, item === null || item === void 0 ? void 0 : item.id));
                    })), title === SectionTitle.MyOpenPositions &&
                        availableTandymPositionList &&
                        (availableTandymPositionList === null || availableTandymPositionList === void 0 ? void 0 : availableTandymPositionList.map(function (item, index) {
                            return item.solution === solutions.MissionCoreSolutions && (_jsxs(Box, __assign({ sx: {
                                    width: '30%',
                                    p: 1,
                                } }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16) }, { children: "Available Tandym Jobs" })), _jsx(Box, __assign({ sx: [
                                            overViewStyles.root,
                                            { display: 'flex', flexWrap: 'wrap' },
                                        ] }, { children: item === null || item === void 0 ? void 0 : item.availableTandymPosition.map(function (item, index) { return (_jsx(Box, __assign({ p: 1 }, { children: _jsx(AvailableTandymPositions, { id: index, sector: item === null || item === void 0 ? void 0 : item.sector, functionalGroupName: item === null || item === void 0 ? void 0 : item.functionalGroupName, positionCount: item === null || item === void 0 ? void 0 : item.count }) }), index + (item === null || item === void 0 ? void 0 : item.functionalGroupName))); }) }))] }), index +
                                SectionTitle.MyOpenPositions +
                                solutions.MissionCoreSolutions));
                        }))] })), latestTandymOpeningIIcon && (_jsx(CustomModal, { id: 'latest-tandym-opening', open: latestTandymOpeningIIcon, onClose: function () { return setLatestTandymOpeningIIcon(false); }, children: topTenVacanciesList &&
                    topTenVacanciesList.map(function (item) {
                        var _a;
                        return (item === null || item === void 0 ? void 0 : item.functionalGroupName) ===
                            topTenVacancyDrillDownFnGrpName && (_jsxs(Box, __assign({ sx: {
                                backgroundColor: "".concat(colorCodes.backgroundColors.white),
                            } }, { children: [_jsxs(Box, __assign({ p: 2 }, { children: [_jsxs(Box, __assign({ gap: 1, sx: overViewStyles.root }, { children: [_jsx(TPSvgIcon, { id: "ContentBox-arrow-".concat(id, "-icon"), "data-testid": "ContentBox-arrow-".concat(id, "-icon"), resourceName: 'info-icon' }), _jsx(Typography, __assign({ sx: InfoIconCarousel.title }, { children: item === null || item === void 0 ? void 0 : item.functionalGroupName }))] })), _jsx(Typography, __assign({ ml: 4, sx: InfoIconCarousel.subTitle }, { children: functionalGroupName(item.functionalGroupName) })), _jsx(Box, { sx: [overViewStyles.imageBorderBottom, { mt: 3 }] }), _jsx(Typography, __assign({ mt: 2, sx: InfoIconCarousel.description }, { children: "Description" })), _jsx(Typography, __assign({ sx: InfoIconCarousel.descriptionText }, { children: item === null || item === void 0 ? void 0 : item.functionalGroupDescription }))] })), _jsxs(Box, __assign({ sx: {
                                        background: "".concat(colorCodes.backgroundColors.alabaster),
                                        ml: 2,
                                    } }, { children: [_jsx(Typography, __assign({ py: 1, sx: InfoIconCarousel.subTitleBlue }, { children: "Latest Tandym Openings" })), _jsx(TPCarousel, { id: 'latest-tandym-opening-carousel', children: (_a = item === null || item === void 0 ? void 0 : item.drillDownData) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                                                var _a, _b, _c;
                                                return (_jsx(Box, { children: _jsx(PopupCarouselCard, { id: "".concat(item === null || item === void 0 ? void 0 : item.id), jobTitle: (_a = item === null || item === void 0 ? void 0 : item.jobTitle) !== null && _a !== void 0 ? _a : ' ', industry: (_b = item === null || item === void 0 ? void 0 : item.industry) !== null && _b !== void 0 ? _b : ' ', location: (_c = item === null || item === void 0 ? void 0 : item.mercuryLocation) !== null && _c !== void 0 ? _c : ' ', setLatestTandymOpeningIIcon: setLatestTandymOpeningIIcon, onClick: function () {
                                                            return handleCardClick({
                                                                jobTitle: item === null || item === void 0 ? void 0 : item.jobTitle,
                                                                industry: item === null || item === void 0 ? void 0 : item.industry,
                                                                location: item === null || item === void 0 ? void 0 : item.mercuryLocation,
                                                            });
                                                        } }) }, item === null || item === void 0 ? void 0 : item.id));
                                            }) })] }))] }), item === null || item === void 0 ? void 0 : item.id));
                    }) }))] })));
};
export default LiveContractorsAndOpenPositionsContainer;
