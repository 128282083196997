var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import Box from '@mui/material/Box';
import './TPToast.scss';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import rightImg from '../../../images/right.png';
import wrongImg from '../../../images/wrong.png';
import warningImg from '../../../images/warning.png';
import TPSvgIcon from '../svg-icon/TPSvgIcon';
import { Typography } from '@mui/material';
import { fontSizeDynamic } from '../fontsizeutils/fontSizeUtils';
var TPToast = function (props) {
    var vertical = props.vertical, horizontal = props.horizontal;
    var _a = React.useState(props.open), open = _a[0], setOpen = _a[1];
    var imageWindowWidth = window.innerWidth / 75;
    var handleClose = function () {
        setOpen(false);
    };
    React.useEffect(function () {
        setOpen(props.open);
    }, [props.open]);
    var getCssClass = function (type) {
        switch (type) {
            case 'success':
                return 'toast-success';
            case 'warning':
                return 'toast-warning';
            case 'info':
                return 'toast-info';
            case 'error':
                return 'toast-error';
            default:
                return 'toast-primary';
        }
    };
    var getImage = function (type) {
        switch (type) {
            case 'success':
                return (_jsx("img", { src: rightImg, alt: 'right-img', className: 'toast-icon', width: imageWindowWidth, height: imageWindowWidth }));
            case 'error':
                return (_jsx("img", { src: wrongImg, alt: 'wrong-img', className: 'toast-icon', width: imageWindowWidth, height: imageWindowWidth }));
            case 'warning':
                return (_jsx("img", { src: warningImg, alt: 'warning-img', className: 'toast-icon', width: imageWindowWidth, height: imageWindowWidth }));
            case 'info':
                return (_jsx("div", __assign({ className: 'toast-icon' }, { children: _jsx(TPSvgIcon, { resourceName: 'icon-info' }) })));
            default:
                return null;
        }
    };
    var action = (_jsx(React.Fragment, { children: _jsx(IconButton, __assign({ size: 'small', "aria-label": 'close', color: 'inherit', onClick: handleClose, className: 'close-btn' }, { children: _jsx(CloseIcon, { fontSize: 'inherit' }) })) }));
    return (_jsx(Box, __assign({ sx: { width: 500 } }, { children: _jsx(Snackbar, { className: getCssClass(props.type), anchorOrigin: { vertical: vertical, horizontal: horizontal }, autoHideDuration: 3000, open: open, onClose: handleClose, message: _jsxs(Box, __assign({ display: 'flex', justifyContent: 'center', alignItems: 'center' }, { children: [_jsx(Box, __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: getImage(props.type) })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(14) }, { children: props.message }))] })), action: action }, vertical + horizontal) })));
};
export default TPToast;
