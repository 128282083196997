import { CircleColors } from './enumValues';
export var tileBackground = function (item) {
    switch (item) {
        case CircleColors.PublicHealth:
            return '#D3E9FF';
        case CircleColors.Corporate:
        case CircleColors.Commercial:
            return '#E0E0E0';
        case CircleColors.EnterpriseApplications:
        case CircleColors.Technology:
            return '#FFEDD1';
    }
};
export var extractedText = function (fullText) {
    var specialCharacterPattern = /[^a-zA-Z0-9\s]/;
    var words = fullText
        .split(' ')
        .filter(function (word) { return !specialCharacterPattern.test(word); });
    if (words.length > 1) {
        return words[0][0] + words[1][0];
    }
    else if (words.length === 1) {
        return words[0][0];
    }
    else {
        return '';
    }
};
export var functionalGroupNameShortForm = function (item) {
    switch (item) {
        case 'Nursing & Therapy':
            return 'Nursing & Therapy';
        case 'Architecture Development & QA':
            return 'Arc,Dev & QA';
        case 'Program & Project Management':
            return 'P&P Management';
        case 'Advanced Practice & Physicians':
            return 'Advanced Practice & Physicians';
        case 'Enterprise Applications':
            return 'ENT Apps';
        case 'Clinical Professionals':
            return 'Clinical Professionals';
        case 'Corporate Services':
            return 'Corporate Services';
        case 'Corporate Finance':
            return 'Corporate Finance';
        case 'Data Center':
            return 'Data Center';
        case 'Infrastructure':
            return 'Infrastructure';
        default:
            return null;
    }
};
export var formatDate = function (date) {
    var d = new Date(date);
    var day = String(d.getDate()).padStart(2, '0');
    var month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    var year = d.getFullYear();
    return "".concat(month, "/").concat(day, "/").concat(year);
};
export var functionalGroupName = function (item) {
    switch (item) {
        case 'Nursing & Therapy':
        case 'Advanced Practice & Physicians':
        case 'Enterprise Applications':
        case 'Architecture Development & QA':
        case 'Program & Project Management':
            return 'Consulting Solutions';
        case 'Clinical Professionals':
        case 'Corporate Services':
        case 'Corporate Finance':
        case 'Data Center':
        case 'Infrastructure':
            return 'Mission Core';
        default:
            return null;
    }
};
