import styled from 'styled-components';
import { Box, Grid } from '@mui/material';
import recruitmentRatiosBg from '../../images/recruitment-ratios-bg.png';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var fontSize = function (targetSize) {
    return "".concat((window.innerWidth / 1920) * (targetSize / 16), "rem");
};
export var RecruitmentRatiosContainer = styled(Box)({
    padding: '20px',
    background: "url(".concat(recruitmentRatiosBg, ")"),
    backgroundSize: 'cover',
    minHeight: '260px',
    borderRadius: '8px',
    marginTop: '10px',
});
export var FillRateBoxStyled = styled(Box)({
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-caption.fill-rate-style': {
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: fontSizeDynamic(12),
        fontWeight: 500,
        letterSpacing: '0.25px',
        marginLeft: '10px',
    },
    '& .MuiTypography-h2.fill-rate-title': {
        color: '#fff',
        fontSize: fontSizeDynamic(20),
        fontWeight: 600,
        letterSpacing: '1px',
    },
});
export var StepsGridStyled = styled(Grid)({
    '.MuiTypography-subtitle1.step-icon': {
        width: '32px',
        height: '32px',
        color: '#fff',
        border: '1px solid #fff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
        lineHeight: 0,
    },
    '& .MuiTypography-caption.step-label': {
        color: '#fff',
        fontSize: fontSizeDynamic(12),
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.25px',
    },
    '& .MuiTypography-h3.step-value': {
        color: '#fff',
        fontSize: fontSizeDynamic(12),
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.25px',
    },
});
export var ArrowContainerGridStyled = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: '-45px',
    '& .MuiTypography-caption.ratio-label': {
        color: '#fff',
        fontSize: fontSizeDynamic(20),
        marginBottom: '15px',
        fontWeight: 300,
    },
});
