var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Modal, CircularProgress } from '@mui/material';
import React from 'react';
var style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    outline: 'none',
};
var TPProgressModal = function (props) {
    var isLoading = props.isLoading, setIsLoading = props.setIsLoading;
    return (_jsx(Modal, __assign({ open: isLoading, "aria-labelledby": 'progress-modal-title', "aria-describedby": 'progress-modal-content', id: 'progress-modal', "data-testid": 'progress-modal' }, { children: _jsx("div", __assign({ style: style }, { children: _jsx(CircularProgress, {}) })) })));
};
export default TPProgressModal;
