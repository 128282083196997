import styled from 'styled-components';
import { Box, Grid } from '@mui/material';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
export var borderColor = '#eaecf0';
export var TPStyledGrid = styled(Grid)({
    padding: '5px',
    '& .MuiTypography-root.MuiTypography-h6': {
        color: '#212121',
        fontSize: fontSizeDynamic(20),
        fontWeight: 400,
    },
    '& .MuiTypography-root.MuiTypography-h5': {
        color: '#fff',
        fontSize: fontSizeDynamic(20),
        fontWeight: 400,
    },
    '& .MuiTypography-root.MuiTypography-h1': {
        color: '#2A70EA',
        fontSize: fontSizeDynamic(40),
        fontWeight: 500,
        lineHeight: '20px',
    },
    '& .MuiTypography-root.MuiTypography-body1': {
        color: '#212121',
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
        lineHeight: '20px',
    },
    '& .MuiFormLabel-root, .MuiFormControlLabel-label': {
        color: '#383838',
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
        lineHeight: '18.8px',
    },
    '& .MuiTypography-root': {
        color: '#686868',
        fontSize: fontSizeDynamic(16),
        fontWeight: 200,
        lineHeight: '18.8px',
    },
    '& .date-picker': {
        color: '#2A70EA',
        fontSize: fontSizeDynamic(16),
    },
    '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
            fontSize: fontSizeDynamic(16),
            color: '#707070',
            border: 'none',
            borderBottom: '1px solid #d9d9d9',
            borderRadius: 0,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Removes all borders
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Removes hover state border
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Removes focus state border
    },
    '& .MuiInputBase-root': {
        borderBottom: '1px solid #d9d9d9',
        borderRadius: 0, // Ensures no border radius
    },
    '& .MuiSelect-select': {
        fontSize: fontSizeDynamic(16),
        lineHeight: '18.8px',
    },
});
export var TPStyledModal = styled(Box)({
    '& h6': {
        color: '#212121',
        fontSize: fontSizeDynamic(16),
        fontWeight: 600,
        fontFamily: 'public sans',
    },
    '& p': {
        color: '#212121',
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
        fontFamily: 'public sans',
    },
    '& ul li': {
        color: '#212121',
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
        lineHeight: '20px',
        fontFamily: 'public sans',
    },
});
