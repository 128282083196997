var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography, FormControlLabel, Checkbox, Grid, FormLabel, FormControl, } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TPButton from "../../../../tandym-web-common/src/shared/components/button/TPButton";
import TPDropdown from "../../../../tandym-web-common/src/shared/components/dropdown/TPDropdown";
import { TPStyledDrawer, TPStyledBox } from "./Placements.styled";
import TPSvgIcon from "../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon";
import { fontSizeDynamic } from "../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils";
import handleSmallMediumLargeSize from "../../../../tandym-web-common/src/shared/utilities/size";
var FilterDrawer = function (_a) {
    var open = _a.open, onClose = _a.onClose, onApply = _a.onApply, employmentTypes = _a.employmentTypes, selectedEmploymentType = _a.selectedEmploymentType, onEmploymentTypeChange = _a.onEmploymentTypeChange, allJobTitles = _a.allJobTitles, selectedJobTitle = _a.selectedJobTitle, onJobTitleChange = _a.onJobTitleChange, allHiringManagers = _a.allHiringManagers, selectedHiringManager = _a.selectedHiringManager, onHiringManagerChange = _a.onHiringManagerChange, allCandidates = _a.allCandidates, selectedCandidate = _a.selectedCandidate, onCandidateChange = _a.onCandidateChange, allTimesheetApprovers = _a.allTimesheetApprovers, selectedTimesheetApprover = _a.selectedTimesheetApprover, onTimesheetApproverChange = _a.onTimesheetApproverChange, allSkillSets = _a.allSkillSets, selectedSkillSet = _a.selectedSkillSet, onSkillSetChange = _a.onSkillSetChange, startDateFrom = _a.startDateFrom, setStartDateFrom = _a.setStartDateFrom, startDateTo = _a.startDateTo, setStartDateTo = _a.setStartDateTo, endDateFrom = _a.endDateFrom, setEndDateFrom = _a.setEndDateFrom, endDateTo = _a.endDateTo, setEndDateTo = _a.setEndDateTo;
    var handleCheckboxChange = function (event) {
        var value = event.target.value;
        var checked = event.target.checked;
        onEmploymentTypeChange(checked
            ? __spreadArray(__spreadArray([], selectedEmploymentType, true), [value], false) : selectedEmploymentType.filter(function (item) { return item !== value; }));
    };
    var filteredApprovers = allTimesheetApprovers.filter(function (approver) { return approver !== null; });
    var dropdownOptions = filteredApprovers.length > 0
        ? filteredApprovers.map(function (approver) { return ({
            label: approver,
            value: approver,
        }); })
        : [{ label: "No timesheet approvers", value: "" }];
    return (_jsx(TPStyledDrawer, __assign({ anchor: "top", open: open, onClose: onClose }, { children: _jsxs(TPStyledBox, { children: [_jsxs(Grid, __assign({ container: true, spacing: 4, sx: { mb: "44px" }, id: "filter-drawer", "data-testid": "filter-drawer" }, { children: [_jsx(Grid, __assign({ item: true, md: 6 }, { children: _jsx(Typography, __assign({ id: "filter-drawer-title", variant: "h6" }, { children: "Filters" })) })), _jsx(Grid, __assign({ item: true, md: 6 }, { children: _jsxs(Typography, __assign({ id: "remark", sx: { display: "flex", justifyContent: "end" }, fontSize: fontSizeDynamic(14) }, { children: [_jsx("span", __assign({ className: "asterick" }, { children: "* " })), "These filter dropdown list contain values only from the placements made."] })) })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: [_jsx(FormLabel, __assign({ className: "formLabel", id: "employment-type-label" }, { children: "Employment Type" })), _jsx("br", {}), employmentTypes.map(function (type) { return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: selectedEmploymentType.includes(type), id: "employment-type-".concat(type), "data-testid": "employment-type-".concat(type), size: handleSmallMediumLargeSize(window.innerWidth), value: type, onChange: handleCheckboxChange }), label: type, componentsProps: {
                                        typography: {
                                            sx: { fontSize: fontSizeDynamic(16) },
                                        },
                                    } }, type)); })] })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { minWidth: "80%" } }, { children: [_jsx(FormLabel, __assign({ className: "formLabel", id: "job-title-label" }, { children: "Job Title" })), _jsx(TPDropdown, { name: "job-title", options: allJobTitles.map(function (jobTitle) { return ({
                                            label: jobTitle,
                                            value: jobTitle,
                                        }); }), id: "job-title", "data-testid": "job-title", defaultSelectedOption: selectedJobTitle, onChange: onJobTitleChange, placeholder: "Select Job Title" })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { minWidth: "80%" } }, { children: [_jsxs(FormLabel, __assign({ className: "formLabel", id: "hiring-manager-label" }, { children: ["Hiring Manager ", _jsx("span", __assign({ className: "asterick" }, { children: "*" }))] })), _jsx(TPDropdown, { name: "hiring-manager", options: allHiringManagers.map(function (hiringManager) { return ({
                                            label: hiringManager,
                                            value: hiringManager,
                                        }); }), id: "hiring-manager", "data-testid": "hiring-manager", defaultSelectedOption: selectedHiringManager, onChange: onHiringManagerChange, placeholder: "Select Hiring Manager" })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { minWidth: "80%" } }, { children: [_jsx(FormLabel, __assign({ className: "formLabel", id: "candidate-name-label" }, { children: "Candidate Name" })), _jsx(TPDropdown, { name: "candidate-name", options: allCandidates.map(function (candidateName) { return ({
                                            label: candidateName,
                                            value: candidateName,
                                        }); }), id: "candidate-name", "data-testid": "candidate-name", defaultSelectedOption: selectedCandidate, onChange: onCandidateChange, placeholder: "Select Candidate" })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { minWidth: "80%" } }, { children: [_jsx(FormLabel, __assign({ className: "formLabel", id: "timesheet-approver-label" }, { children: "Timesheet Approver" })), _jsx(TPDropdown, { name: "timesheet-approver", options: dropdownOptions, id: "timesheet-approver", "data-testid": "timesheet-approver", defaultSelectedOption: selectedTimesheetApprover, onChange: onTimesheetApproverChange, placeholder: "Select Timesheet Approver" })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { minWidth: "80%" } }, { children: [_jsxs(FormLabel, __assign({ className: "formLabel", id: "functional-skill-label" }, { children: ["Functional Skill Set ", _jsx("span", __assign({ className: "asterick" }, { children: "*" }))] })), _jsx(TPDropdown, { name: "functional-skill", options: allSkillSets.map(function (skillSet) { return ({
                                            label: skillSet,
                                            value: skillSet,
                                        }); }), id: "functional-skill", "data-testid": "functional-skill", defaultSelectedOption: selectedSkillSet, onChange: onSkillSetChange, placeholder: "Select Functional Skill Set" })] })) }))] })), _jsxs(Grid, __assign({ container: true, spacing: 4, sx: { mb: "44px" } }, { children: [_jsx(Grid, __assign({ item: true, md: 12 }, { children: _jsx(Typography, __assign({ id: "filter-drawer-title", variant: "h6" }, { children: "Date Filter" })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 12 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { width: "67%" } }, { children: [_jsx(FormLabel, __assign({ sx: { color: "#2A70EA !important" } }, { children: "Start Date" })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(DatePicker, { label: "From", value: startDateFrom
                                                            ? new AdapterDayjs().date(startDateFrom.toString())
                                                            : null, onChange: function (date) {
                                                            return setStartDateFrom((date === null || date === void 0 ? void 0 : date.toDate()) || null);
                                                        }, slotProps: {
                                                            textField: {
                                                                variant: "standard",
                                                                inputProps: {
                                                                    className: "date-picker",
                                                                    id: "date-picker",
                                                                    "data-testid": "date-picker",
                                                                    style: { fontSize: fontSizeDynamic(16) },
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: "date-picker-icon", id: "date-picker-icon", "data-testid": "date-picker-icon" })); },
                                                        }, sx: { width: "78%" } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(DatePicker, { label: "To", value: startDateTo
                                                            ? new AdapterDayjs().date(startDateTo.toString())
                                                            : null, onChange: function (date) {
                                                            return setStartDateTo((date === null || date === void 0 ? void 0 : date.toDate()) || null);
                                                        }, slotProps: {
                                                            textField: {
                                                                variant: "standard",
                                                                inputProps: {
                                                                    className: "date-picker",
                                                                    id: "date-picker",
                                                                    "data-testid": "date-picker",
                                                                    style: { fontSize: fontSizeDynamic(16) },
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: "date-picker-icon", id: "date-picker-icon", "data-testid": "date-picker-icon" })); },
                                                        }, sx: { width: "78%" } }) }))] })) }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 12 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { width: "67%" } }, { children: [_jsx(FormLabel, __assign({ sx: { color: "#2A70EA !important" } }, { children: "End Date" })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(DatePicker, { label: "From", value: endDateFrom
                                                            ? new AdapterDayjs().date(endDateFrom.toString())
                                                            : null, onChange: function (date) {
                                                            return setEndDateFrom((date === null || date === void 0 ? void 0 : date.toDate()) || null);
                                                        }, slotProps: {
                                                            textField: {
                                                                variant: "standard",
                                                                inputProps: {
                                                                    className: "date-picker",
                                                                    id: "date-picker",
                                                                    "data-testid": "date-picker",
                                                                    style: { fontSize: fontSizeDynamic(16) },
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: "date-picker-icon", id: "date-picker-icon", "data-testid": "date-picker-icon" })); },
                                                        }, sx: { width: "78%" } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(DatePicker, { label: "To", value: endDateTo
                                                            ? new AdapterDayjs().date(endDateTo.toString())
                                                            : null, onChange: function (date) { return setEndDateTo((date === null || date === void 0 ? void 0 : date.toDate()) || null); }, slotProps: {
                                                            textField: {
                                                                variant: "standard",
                                                                inputProps: {
                                                                    className: "date-picker",
                                                                    id: "date-picker",
                                                                    "data-testid": "date-picker",
                                                                    style: { fontSize: fontSizeDynamic(16) },
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: "date-picker-icon", id: "date-picker-icon", "data-testid": "date-picker-icon" })); },
                                                        }, sx: { width: "78%" } }) }))] })) }))] })) }))] })), _jsx(Grid, __assign({ container: true, direction: "row", justifyContent: "end" }, { children: _jsxs(Grid, __assign({ item: true }, { children: [_jsx(TPButton, { children: "Cancel", color: "secondary", id: "discard-changes-btn", "data-testid": "discard-changes-btn", onClick: onClose, sx: { mr: "15px", width: "150px", fontSize: fontSizeDynamic(14) } }), _jsx(TPButton, { children: "Apply", color: "primary", id: "save-personal-details", "data-testid": "save-personal-details", onClick: onApply, sx: { width: "150px", fontSize: fontSizeDynamic(14) } })] })) }))] }) })));
};
export default FilterDrawer;
