var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, List, ListItem, ListItemButton, Accordion, AccordionSummary, AccordionDetails, ListItemText, Typography, useMediaQuery, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink, useNavigate } from 'react-router-dom';
import { fontSizeDynamic } from '../../shared/components/fontsizeutils/fontSizeUtils';
import CloseIcon from '@mui/icons-material/Close';
import { colorCodes } from '../../shared/components/styles/ColorCodesList';
var SideBar = function (props) {
    var tabs = props.tabs, setIsShowSidebar = props.setIsShowSidebar, handleLogout = props.handleLogout;
    var navigate = useNavigate();
    var isScreenSmall = useMediaQuery('(max-width:900px)');
    var handleNavLinkClick = function (path) {
        setIsShowSidebar(false);
        navigate(path);
    };
    var isActiveTab = function (tab) {
        return window.location.pathname.includes(tab);
    };
    return (_jsxs(Box, __assign({ sx: {
            width: 250,
            display: 'flex',
            flexDirection: 'column',
            height: '95vh',
            justifyContent: 'space-between',
        }, role: 'presentation', id: 'drawerList', "data-testid": 'drawerList', onClick: function () { return setIsShowSidebar(false); } }, { children: [_jsx("nav", __assign({ "aria-label": 'secondary' }, { children: _jsxs(List, { children: [_jsx(ListItem, __assign({ sx: { cursor: 'pointer' } }, { children: _jsx(CloseIcon, { onClick: function () { return setIsShowSidebar(false); } }) })), tabs
                            .filter(function (tab) { return tab.show; })
                            .map(function (tab, index) {
                            var _a;
                            return (_jsx(ListItem, __assign({ disablePadding: true, id: "".concat(index, "-").concat(tab.label), "data-testid": "".concat(index, "-").concat(tab.label), sx: { mb: '5px', cursor: 'pointer' } }, { children: tab.path ? (_jsx(ListItemButton, { children: _jsx(NavLink, __assign({ id: "listItem-".concat(index, "-").concat(tab.label), "data-testid": "listItem-".concat(index, "-").concat(tab.label), to: "".concat(tab.path), style: {
                                            fontSize: isScreenSmall
                                                ? '1rem'
                                                : fontSizeDynamic(24),
                                            fontWeight: 400,
                                            textDecoration: isActiveTab(tab.path)
                                                ? 'underline'
                                                : 'none',
                                            textUnderlineOffset: isActiveTab(tab.path)
                                                ? '10px'
                                                : 'none',
                                            color: isActiveTab(tab.path)
                                                ? colorCodes.iconBackgroundColor.royalBlue
                                                : colorCodes.textColors.lightGray,
                                            cursor: 'pointer',
                                        } }, { children: tab.label })) })) : (_jsxs(Accordion, __assign({ onClick: function (event) { return event.stopPropagation(); }, sx: {
                                        '& .MuiButtonBase-root MuiAccordionSummary-root': {
                                            padding: 0,
                                        },
                                        background: 'transparent',
                                        color: '#fff',
                                        boxShadow: 'none',
                                        '& .MuiAccordionSummary-content': {
                                            margin: '12px 0',
                                        },
                                    } }, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": 'panel1a-content', id: 'panel1a-header', sx: {
                                                '& .MuiAccordionSummary-expandIconWrapper': {
                                                    color: '#fff',
                                                },
                                                '& .MuiAccordionSummary-content': {
                                                    margin: 0,
                                                },
                                            } }, { children: _jsx(ListItemText, { primary: tab.label, sx: {
                                                    fontSize: isScreenSmall
                                                        ? '1rem'
                                                        : fontSizeDynamic(24),
                                                } }) })), _jsx(AccordionDetails, __assign({ sx: {
                                                padding: '0px 16px',
                                            } }, { children: _jsx(List, { children: (_a = tab.subTabs) === null || _a === void 0 ? void 0 : _a.map(function (subTab, index) { return (_jsx(ListItem, __assign({ sx: {
                                                        '& .MuiButtonBase-root MuiAccordionSummary-root': {
                                                            minHeight: '38px',
                                                        },
                                                    } }, { children: _jsx(ListItemButton, __assign({ sx: {
                                                            minHeight: '38px !important',
                                                        }, onClick: function () { return handleNavLinkClick(subTab.path); } }, { children: _jsx(Typography, __assign({ sx: {
                                                                fontSize: fontSizeDynamic(24),
                                                            }, id: "subtab-".concat(subTab.label, "-").concat(index), "data-testid": "subtab-".concat(subTab.label, "-").concat(index) }, { children: subTab.label })) })) }), index)); }) }) }))] }))) }), index));
                        })] }) })), _jsx(Typography, __assign({ sx: {
                    marginLeft: 2,
                    fontSize: isScreenSmall ? '1rem' : fontSizeDynamic(24),
                    fontWeight: 400,
                    '&:hover': {
                        color: colorCodes.iconBackgroundColor.royalBlue,
                    },
                    color: colorCodes.textColors.lightGray,
                    cursor: 'pointer',
                }, onClick: handleLogout }, { children: "LogOut" }))] })));
};
export default SideBar;
