import { colorCodes } from "../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList";
import { SectionTitle } from "../../../shared/utilities/enumValues";
export var sectionData = [
    { title: SectionTitle.MyLiveContractors },
    { title: SectionTitle.MyOpenPositions },
];
export var totalLiveContractorData = [
    {
        title: "Total My Live Contractors",
        totalCount: "20",
    },
    {
        title: "Consulting Solutions",
        totalCount: "11",
    },
    {
        title: "Mission Core Solutions",
        totalCount: "09",
    },
];
export var totalActivetConractorData = [
    {
        title: "Total My Open Jobs",
        totalCount: "74",
    },
    {
        title: "Consulting Solutions",
        totalCount: "2",
    },
    {
        title: "Mission Core Solutions",
        totalCount: "70",
    },
];
export var circleColorCodes = [
    { color: "".concat(colorCodes.circle.cloud), text: "Corporate" },
    {
        color: "".concat(colorCodes.iconBackgroundColor.royalBlue),
        text: "Public Health",
    },
    { color: "".concat(colorCodes.circle.pumpkinOrange), text: "Technology" },
];
