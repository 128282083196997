var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    clientMainTabNavigationGuard: false,
    clientNavigationGuardModalPopup: false,
    clientNavigationHistory: "",
};
var clientTabNavigationGuardSlice = createSlice({
    name: "tabNavigationGuard",
    initialState: initialState,
    reducers: {
        setClientTabNavigationGuard: function (state, action) {
            state.clientMainTabNavigationGuard =
                action.payload.clientMainTabNavigationGuard;
        },
        setClientNavigationGuardModalPopup: function (state, action) {
            state.clientNavigationGuardModalPopup =
                action.payload.clientNavigationGuardModalPopup;
        },
        setClientNavigationHistory: function (state, action) {
            state.clientNavigationHistory = action.payload.clientNavigationHistory;
        },
    },
});
export var setClientTabNavigationGuard = (_a = clientTabNavigationGuardSlice.actions, _a.setClientTabNavigationGuard), setClientNavigationGuardModalPopup = _a.setClientNavigationGuardModalPopup, setClientNavigationHistory = _a.setClientNavigationHistory;
export default clientTabNavigationGuardSlice.reducer;
