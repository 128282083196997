export var isMobile = function () {
    return window.innerWidth <= 768;
};
export var isMobileEntitlementFlag = function (entitlementFlagKey, entitlementsData) {
    var _a, _b;
    // Check if the entitlementFlagKey exists in isMobileJson
    if (entitlementFlagKey in ((_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.feMobileEntitlement)) {
        return (_b = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _b === void 0 ? void 0 : _b.feMobileEntitlement[entitlementFlagKey];
    }
    return false;
};
