var handleSmallMediumLargeSize = function (item) {
    if (item <= 1000) {
        return 'small';
    }
    else if (item > 1000 && item <= 1500) {
        return 'medium';
    }
    else {
        return 'large';
    }
};
export default handleSmallMediumLargeSize;
