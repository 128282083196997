import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { colorCodes } from '../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
export var homeTabStyles = {
    ContentBoxStyle: {
        p: 2,
        pt: 0,
        pr: 0,
        mb: 2,
        backgroundColor: colorCodes.backgroundColors.white,
    },
    smallGridStyle: {
        p: 4,
        mt: 2,
        ml: window.innerWidth > 768 ? 2 : 0,
        backgroundColor: colorCodes.backgroundColors.white,
    },
    titleStyle: {
        color: colorCodes.textColors.black,
        fontSize: fontSizeDynamic(16),
        fontWeight: 500,
    },
};
