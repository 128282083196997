export var isMobile = function () {
    return window.innerWidth <= 768;
};
var isMobileJson = {
    timesheets: true,
};
export var isMobileEntitlementFlag = function (entitlementFlagKey) {
    // Check if the entitlementFlagKey exists in isMobileJson
    if (entitlementFlagKey in isMobileJson) {
        return isMobileJson[entitlementFlagKey];
    }
    return false;
};
