var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import React from 'react';
var ErrorPage = function (props) {
    return (_jsx(Box, __assign({ sx: { textAlign: 'center', mt: '30px' } }, { children: _jsxs(Typography, __assign({ component: 'h1' }, { children: [' ', "This page is currently not available"] })) })));
};
export default ErrorPage;
