var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    mainTabNavigationGuard: false,
    navigationGuardModalPopup: false,
    navigationHistory: '',
};
var tabNavigationGuardSlice = createSlice({
    name: 'tabNavigationGuard',
    initialState: initialState,
    reducers: {
        setTabNavigationGuard: function (state, action) {
            state.mainTabNavigationGuard = action.payload.mainTabNavigationGuard;
        },
        setNavigationGuardModalPopup: function (state, action) {
            state.navigationGuardModalPopup =
                action.payload.navigationGuardModalPopup;
        },
        setNavigationHistory: function (state, action) {
            state.navigationHistory = action.payload.navigationHistory;
        },
    },
});
export var setTabNavigationGuard = (_a = tabNavigationGuardSlice.actions, _a.setTabNavigationGuard), setNavigationGuardModalPopup = _a.setNavigationGuardModalPopup, setNavigationHistory = _a.setNavigationHistory;
export default tabNavigationGuardSlice.reducer;
