var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import TimeApprovalProceduresImg from "../../images/TimeApprovalProceduresImg.png";
import { fontSizeDynamic } from "../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils";
import { colorCodes } from "../../../../tandym-web-common/src/shared/components/styles/ColorCodesList";
var TypographyFontStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  color: #ffffff;\n  font-weight: 400;\n"], ["\n  font-size: ", ";\n  color: #ffffff;\n  font-weight: 400;\n"])), fontSizeDynamic(32));
var TypographyH6Style = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  color: #ffffff;\n  font-weight: 300;\n  margin-top: 19.2px;\n"], ["\n  font-size: ", ";\n  color: #ffffff;\n  font-weight: 300;\n  margin-top: 19.2px;\n"])), fontSizeDynamic(18));
var Width44Style = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 44%;\n"], ["\n  width: 44%;\n"])));
export var TimeApprovalProceduresStyle = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .MuiBox-root.img-1 {\n    padding-top: 130px;\n    padding-bottom: 230px;\n    padding-left: 40px;\n    position: relative;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center center;\n    flex: 1;\n    background-image: url(\"", "\");\n    .MuiTypography-h2 {\n      color: #ffffff;\n      ", "\n    }\n    .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n    .action-btns {\n      margin-top: 48px;\n    }\n  }\n"], ["\n  .MuiBox-root.img-1 {\n    padding-top: 130px;\n    padding-bottom: 230px;\n    padding-left: 40px;\n    position: relative;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center center;\n    flex: 1;\n    background-image: url(\"", "\");\n    .MuiTypography-h2 {\n      color: #ffffff;\n      ", "\n    }\n    .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n    .action-btns {\n      margin-top: 48px;\n    }\n  }\n"])), TimeApprovalProceduresImg, TypographyFontStyle, Width44Style, TypographyH6Style);
export var aboutInfoCenterStyle = {
    containerBox: {
        display: "flex",
        justifyContent: "space-between",
        justifyItems: "center",
    },
    infoCenterTitle: {
        color: colorCodes.textColors.midNightMoss,
        fontSize: fontSizeDynamic(24),
        fontWeight: 400,
    },
    needHelpText: {
        color: colorCodes.textColors.midNightMoss,
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
    },
    faqText: {
        color: colorCodes.iconBackgroundColor.royalBlue,
        fontSize: fontSizeDynamic(16),
        fontWeight: 600,
        "&:hover": {
            cursor: "pointer",
        },
    },
    backgroundImgTitle: {
        color: colorCodes.backgroundColors.white,
        fontSize: fontSizeDynamic(32),
        fontWeight: 400,
    },
    backgroundImgDescription: {
        color: colorCodes.backgroundColors.white,
        fontSize: fontSizeDynamic(18),
        fontWeight: 300,
    },
    subContainerBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: colorCodes.backgroundColors.black,
        flex: 1,
    },
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
