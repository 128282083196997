import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
var ProtectedRoute = function (_a) {
    var children = _a.children;
    var authToken = sessionStorage.getItem('authToken');
    var isAuthenticated = authToken ? true : false;
    var location = useLocation();
    if (!isAuthenticated) {
        return _jsx(Navigate, { to: '/login', state: { from: location }, replace: true });
    }
    return _jsx(_Fragment, { children: children });
};
export default ProtectedRoute;
