var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Box from '@mui/material/Box';
var NoPageFound = function () {
    return (_jsx(Box, __assign({ sx: { display: 'flex', justifyContent: 'center', mt: '3rem' } }, { children: "This page is currently not available." })));
};
export default NoPageFound;
