var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Input as BaseInput } from '@mui/material';
import { Box, styled } from '@mui/system';
function OTP(_a) {
    var length = _a.length, value = _a.value, onChange = _a.onChange;
    var inputRefs = React.useRef(new Array(length).fill(null));
    var focusInput = function (index) {
        var _a;
        (_a = inputRefs.current[index]) === null || _a === void 0 ? void 0 : _a.focus();
    };
    var handleKeyDown = function (event, currentIndex) {
        switch (event.key) {
            case 'ArrowLeft':
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                }
                break;
            case 'ArrowRight':
                if (currentIndex < length - 1) {
                    focusInput(currentIndex + 1);
                }
                break;
            case 'Backspace':
                event.preventDefault();
                var updated = value.split('');
                updated[currentIndex] = '';
                onChange(updated.join(''));
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                }
                break;
            default:
                break;
        }
    };
    var handleChange = function (event, currentIndex) {
        var inputValue = event.target.value;
        if (!/^\d*$/.test(inputValue))
            return; // Allow only numeric input
        var updated = value.split('');
        updated[currentIndex] = inputValue.slice(-1); // Take the last character in case of multiple input
        onChange(updated.join(''));
        if (inputValue && currentIndex < length - 1) {
            focusInput(currentIndex + 1);
        }
    };
    var handlePaste = function (event) {
        event.preventDefault();
        // Get only numeric characters from the pasted content
        var pasteData = event.clipboardData
            .getData('text')
            .replace(/\D/g, '') // Remove non-numeric characters
            .slice(0, length); // Limit to the OTP length
        var updated = value.split('');
        pasteData.split('').forEach(function (char, i) {
            if (i < length) {
                updated[i] = char; // Replace current OTP with pasted values
            }
        });
        onChange(updated.join('')); // Update the parent state with the new OTP
    };
    return (_jsx(Box, __assign({ sx: { display: 'flex', gap: 1, alignItems: 'center' } }, { children: Array.from({ length: length }, function (_, index) { return (_jsx(React.Fragment, { children: _jsx(BaseInput, { placeholder: '*', slots: {
                    input: StyledInputElement,
                }, type: 'tel', inputMode: 'numeric', id: "Digit ".concat(index + 1, " of OTP"), "data-testid": "Digit ".concat(index + 1, " of OTP"), "aria-label": "Digit ".concat(index + 1, " of OTP"), inputProps: {
                    inputMode: 'numeric',
                    ref: function (el) {
                        inputRefs.current[index] = el;
                    },
                    value: value[index] || '',
                    onKeyDown: function (event) {
                        return handleKeyDown(event, index);
                    },
                    onChange: function (event) {
                        return handleChange(event, index);
                    },
                    onPaste: index === 0 ? handlePaste : undefined,
                    maxLength: 1,
                } }) }, index)); }) })));
}
function OTPInput(props) {
    var otp = props.otp, setOtp = props.setOtp;
    return (_jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', gap: 2 } }, { children: _jsx(OTP, { value: otp, onChange: setOtp, length: 6 }) })));
}
var StyledInputElement = styled(React.forwardRef(function (props, ref) { return _jsx("input", __assign({}, props, { ref: ref })); }))(function (_a) {
    var theme = _a.theme;
    return "\n  width: 100%;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.5;\n  padding: 8px 0;\n  border-radius: none;\n  text-align: center;\n  border:none;\n  background: none;\n  };\n";
});
export default OTPInput;
