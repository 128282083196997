var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Divider, Grid, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, } from '@mui/material';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { CustomScrollContainer, StyledTableRow, } from '../../pages/placements/Placements.styled';
import CustomPagination from '../../pages/placements/CustomPagination';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { formatDate } from '../../shared/utilities/utilities';
import { liveContractorDrillDownStyles, overViewStyles, } from '../../pages/Home/Overview/OverViewStyles';
import bannerImage from '../../images/openPositionsIIconCarouselBackgroundImg.png';
import { colorCodes } from '../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import { solutions } from '../../shared/utilities/enumValues';
var ContractorsBillDrilldown = function (_a) {
    var onBackClick = _a.onBackClick, title = _a.title, myData = _a.myData;
    var rowsPerPage = 7;
    var _b = useState(0), page = _b[0], setPage = _b[1];
    var _c = useState([]), sortedData = _c[0], setSortedData = _c[1];
    var _d = useState({}), sortOrder = _d[0], setSortOrder = _d[1];
    var _e = useState([]), consultingSolutions = _e[0], setConsultingSolutions = _e[1];
    var _f = useState([]), missionCoreSolutions = _f[0], setMissionCoreSolutions = _f[1];
    var _g = useState(title), currentTitle = _g[0], setCurrentTitle = _g[1];
    var _h = useState(null), activeFilter = _h[0], setActiveFilter = _h[1];
    var isScreenWidth900 = useMediaQuery('(min-width:900px)');
    useEffect(function () {
        if (myData.data) {
            var myDataSorted = myData.data.map(function (item) { return (__assign(__assign({}, item), { startDate: new Date(item.startDate), endDate: new Date(item.endDate) })); });
            setSortedData(myDataSorted);
            var consultingSolutionsData = myData === null || myData === void 0 ? void 0 : myData.data.filter(function (item) {
                return item.solution === solutions.ConsultingSolutions;
            });
            var missionCoreSolutionsData = myData === null || myData === void 0 ? void 0 : myData.data.filter(function (item) {
                return item.solution === solutions.MissionCoreSolutions;
            });
            setConsultingSolutions(consultingSolutionsData);
            setMissionCoreSolutions(missionCoreSolutionsData);
        }
        else {
            console.error('No contractor data available');
        }
    }, [myData]);
    var columnKeyMap = useMemo(function () { return ({
        Candidate: 'candidateName',
        Client: 'client',
        Start: 'startDate',
        End: 'endDate',
        'Job Title': 'jobTitle',
        Consultant: 'consultant',
        '': 'emptyColumn', // Add an empty column key
    }); }, []);
    var handleSort = useCallback(function (columnDisplayName) {
        var columnKey = columnKeyMap[columnDisplayName];
        var isAsc = sortOrder[columnKey] === 'asc';
        var order = isAsc ? 'desc' : 'asc';
        setSortOrder(function (prevSortOrder) {
            var _a;
            return (__assign(__assign({}, prevSortOrder), (_a = {}, _a[columnKey] = order, _a)));
        });
        var sorted = __spreadArray([], sortedData, true).sort(function (a, b) {
            var aValue = a[columnKey];
            var bValue = b[columnKey];
            if (columnKey === 'startDate' || columnKey === 'endDate') {
                var dateA = new Date(aValue);
                var dateB = new Date(bValue);
                return order === 'asc'
                    ? dateA.getTime() - dateB.getTime()
                    : dateB.getTime() - dateA.getTime();
            }
            if (aValue == null && bValue == null)
                return 0;
            if (aValue == null)
                return order === 'asc' ? 1 : -1;
            if (bValue == null)
                return order === 'asc' ? -1 : 1;
            return aValue < bValue
                ? order === 'asc'
                    ? -1
                    : 1
                : order === 'asc'
                    ? 1
                    : -1;
        });
        setSortedData(sorted);
    }, [columnKeyMap, sortOrder, sortedData]);
    var isCSAvailable = consultingSolutions.length > 0;
    var isMCSAvailable = missionCoreSolutions.length > 0;
    return (_jsxs(Grid, __assign({ sx: { background: '#fff', mt: 2 }, id: 'contractors-billing-drilldown' }, { children: [_jsx(Grid, __assign({ id: 'contractors-billing-drilldown-banner', sx: [
                    overViewStyles.image,
                    {
                        backgroundImage: isScreenWidth900 ? "url(".concat(bannerImage, ")") : '',
                        width: '100%',
                        borderBottom: '1px solid #EAECF0',
                    },
                ] }, { children: _jsxs(Grid, __assign({ container: true, sx: {
                        padding: '20px',
                    } }, { children: [_jsxs(Grid, __assign({ item: true, sm: 12, md: 2, lg: 3, sx: { width: { sm: '100%', md: 'auto' } } }, { children: [_jsxs(Box, __assign({ id: 'section-1' }, { children: [_jsx(Link, __assign({ underline: 'none', onClick: onBackClick, "data-testid": 'back-click', id: 'back-click' }, { children: _jsx(TPSvgIcon, { resourceName: 'arrow-icon-left' }) })), _jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ sx: {
                                                display: 'inline-block',
                                                position: 'relative',
                                                bottom: '3px',
                                                // color: '#2A70EA',
                                            }, fontSize: fontSizeDynamic(24), "data-testid": 'contractors-billing-drill-down-title' }, { children: title })), currentTitle !== 'Contractors on Billing' && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ sx: {
                                                        display: 'inline-block',
                                                        position: 'relative',
                                                        bottom: '3px',
                                                        px: '5px',
                                                    }, fontSize: fontSizeDynamic(24) }, { children: "/" })), _jsx(Typography, __assign({ sx: {
                                                        display: 'inline-block',
                                                        position: 'relative',
                                                        bottom: '3px',
                                                        color: '#2A70EA',
                                                    }, fontSize: fontSizeDynamic(24), "data-testid": 'live-contractor-drill-down-title' }, { children: currentTitle }))] }))] })), _jsxs(Box, __assign({ mt: '24px' }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(12), id: 'live-contractor-drill-down-subtitle', "data-testid": 'live-contractor-drill-down-subtitle', sx: liveContractorDrillDownStyles.functionalGroupName }, { children: "Total Contractors on Billing" })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(40), sx: liveContractorDrillDownStyles.totalValue }, { children: sortedData.length }))] }))] })), _jsx(Grid, __assign({ item: true, sm: 2, md: 1, lg: 1, sx: { display: 'inline-flex' } }, { children: _jsx(Divider, { sx: [
                                    liveContractorDrillDownStyles.divider,
                                    { display: { xs: 'none', sm: 'none', md: 'block' } },
                                ], orientation: 'vertical', variant: 'middle', flexItem: true }) })), _jsx(Grid, __assign({ item: true, sm: 7, md: 7, lg: 4 }, { children: _jsxs(Box, __assign({ id: 'section-2', className: 'drill-down-header', sx: {
                                    paddingTop: '8px',
                                } }, { children: [isMCSAvailable && (_jsxs(Box, __assign({ className: 'mission-core-solutions-header', sx: {
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            gap: '1rem',
                                        } }, { children: [_jsx(Box, __assign({ className: 'functional-group-text' }, { children: _jsx(Typography, __assign({ fontSize: fontSizeDynamic(12), "data-testid": 'mission-core-solutions-text', id: 'mission-core-solutions-text', sx: liveContractorDrillDownStyles.functionalGroupName }, { children: "Mission Core Solutions" })) })), _jsx(Box, __assign({ sx: liveContractorDrillDownStyles.filterBoxes }, { children: missionCoreSolutions
                                                    .filter(function (item, index, self) {
                                                    return index ===
                                                        self.findIndex(function (t) {
                                                            return t.solution === item.solution &&
                                                                t.functionalGroup === item.functionalGroup;
                                                        });
                                                })
                                                    .map(function (item) {
                                                    var isActive = activeFilter === item.functionalGroup;
                                                    return (_jsx(Box, __assign({ className: 'mission-core-solutions-circle', id: "live-contractor-mission-core-solutions-".concat(item.functionalGroup), "data-testid": "live-contractor-mission-core-solutions-".concat(item.functionalGroup), sx: __assign(__assign({}, liveContractorDrillDownStyles.functionalGroupCircle), { backgroundColor: isActive
                                                                ? colorCodes.iconBackgroundColor.royalBlue
                                                                : '', color: isActive
                                                                ? colorCodes.backgroundColors.white
                                                                : '', borderColor: isActive
                                                                ? colorCodes.borderColor.QuillGray
                                                                : '', cursor: 'pointer' }), fontSize: fontSizeDynamic(12) }, { children: item.functionalGroup }), item.id));
                                                }) }))] }))), isCSAvailable && (_jsxs(Box, __assign({ className: 'consulting-solutions-header', sx: [
                                            liveContractorDrillDownStyles.consultingSolutionsHeader,
                                            { left: { sm: '0', md: '-65px' } },
                                        ] }, { children: [_jsx(Box, __assign({ className: 'functional-group-text' }, { children: _jsx(Typography, __assign({ fontSize: fontSizeDynamic(12), "data-testid": 'consulting-solutions-text', id: 'consulting-solutions-text', sx: liveContractorDrillDownStyles.functionalGroupName }, { children: "Consulting Solutions" })) })), _jsx(Box, __assign({ sx: liveContractorDrillDownStyles.filterBoxes }, { children: consultingSolutions
                                                    .filter(function (item, index, self) {
                                                    return index ===
                                                        self.findIndex(function (t) {
                                                            return t.solution === item.solution &&
                                                                t.functionalGroup === item.functionalGroup;
                                                        });
                                                })
                                                    .map(function (item) {
                                                    var isActive = activeFilter === item.functionalGroup;
                                                    return (_jsx(Box, __assign({ className: 'consulting-solutions-circle', id: "live-contractor-consulting-solutions-".concat(item.functionalGroup), "data-testid": "live-contractor-consulting-solutions-".concat(item.functionalGroup), sx: __assign(__assign({}, liveContractorDrillDownStyles.functionalGroupCircle), { backgroundColor: isActive
                                                                ? colorCodes.iconBackgroundColor.royalBlue
                                                                : '', color: isActive
                                                                ? colorCodes.backgroundColors.white
                                                                : '', borderColor: isActive
                                                                ? colorCodes.borderColor.QuillGray
                                                                : '', cursor: 'pointer' }), fontSize: fontSizeDynamic(12) }, { children: item.functionalGroup }), item.id));
                                                }) }))] })))] })) }))] })) })), _jsxs(Grid, { children: [_jsx(TableContainer, __assign({ component: Paper, sx: { maxHeight: 440, overflowX: 'auto' } }, { children: _jsx(CustomScrollContainer, { children: _jsxs(Table, __assign({ sx: { background: '#fff' } }, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: [
                                                'Candidate',
                                                'Client',
                                                'Start',
                                                'End',
                                                'Job Title',
                                                'Consultant',
                                            ].map(function (columnDisplayName) {
                                                var columnKey = columnKeyMap[columnDisplayName];
                                                return (_jsx(TableCell, __assign({ style: { fontSize: fontSizeDynamic(13) } }, { children: columnKey === 'emptyColumn' ? (_jsx(Typography, __assign({ variant: 'h6', sx: { fontWeight: 'bold' } }, { children: columnDisplayName.toUpperCase() }))) : (_jsxs(Stack, __assign({ id: "table-header-".concat(columnDisplayName), "data-testid": "table-header-".concat(columnDisplayName), direction: 'row', spacing: 1, alignItems: 'center', sx: { cursor: 'pointer' }, onClick: function () { return handleSort(columnDisplayName); } }, { children: [_jsx(Typography, { children: columnDisplayName.toUpperCase() }), _jsx(TPSvgIcon, { resourceName: 'sort-icon', id: "sort-icon-".concat(columnDisplayName), "data-testid": "sort-icon-".concat(columnDisplayName) })] }))) }), columnDisplayName));
                                            }) }) }), _jsx(TableBody, { children: sortedData.length > 0 ? (sortedData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(function (item, index) { return (_jsxs(StyledTableRow, { children: [_jsx(TableCell, __assign({ style: { fontSize: fontSizeDynamic(13) } }, { children: item.candidateName })), _jsx(TableCell, __assign({ style: { fontSize: fontSizeDynamic(13) } }, { children: item.client })), _jsx(TableCell, __assign({ style: { fontSize: fontSizeDynamic(13) } }, { children: item.startDate ? formatDate(item.startDate) : 'N/A' })), _jsx(TableCell, __assign({ style: { fontSize: fontSizeDynamic(13) } }, { children: item.endDate ? formatDate(item.endDate) : 'N/A' })), _jsx(TableCell, __assign({ style: { fontSize: fontSizeDynamic(13) } }, { children: item.jobTitle })), _jsx(TableCell, __assign({ style: { fontSize: fontSizeDynamic(13) } }, { children: item.consultant }))] }, 'table-data-' + index)); })) : (_jsx(StyledTableRow, { children: _jsx(TableCell, __assign({ colSpan: 6, align: 'center' }, { children: "No data available" })) })) })] })) }) })), _jsx(CustomPagination, { count: sortedData.length, page: page, rowsPerPage: rowsPerPage, onPageChange: function (_, newPage) { return setPage(newPage); } })] })] })));
};
export default ContractorsBillDrilldown;
