var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colorCodes } from '../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import { customTabsStyle } from './CutomTabsStyle';
var TabPanel = function (_a) {
    var children = _a.children, value = _a.value, index = _a.index, other = __rest(_a, ["children", "value", "index"]);
    return (_jsx(Box, __assign({ role: 'tabpanel', hidden: value !== index, id: "tabpanel-".concat(index), "aria-labelledby": "tab-".concat(index) }, other, { children: value === index && _jsx(Box, { children: children }) })));
};
var CommonTabs = function (_a) {
    var tabs = _a.tabs;
    var _b = useState(function () {
        var savedValue = sessionStorage.getItem('activeTab');
        return savedValue !== null ? parseInt(savedValue, 10) : 0;
    }), value = _b[0], setValue = _b[1];
    useEffect(function () {
        sessionStorage.setItem('activeTab', value.toString());
    }, [value]);
    var handleChange = function (event, newValue) {
        setValue(newValue > 0 ? newValue : 0);
    };
    return (_jsxs(Box, { children: [_jsx(Tabs, __assign({ variant: 'scrollable', scrollButtons: 'auto', "aria-label": 'scrollable auto tabs', value: value, onChange: handleChange, sx: {
                    background: colorCodes.backgroundColors.white,
                } }, { children: tabs
                    .filter(function (tab) { return tab.show; })
                    .map(function (tab, index) { return (_jsx(Tab, { label: tab.label, sx: value === index
                        ? customTabsStyle.active
                        : customTabsStyle.inActive }, index)); }) })), tabs.map(function (tab, index) { return (_jsx(TabPanel, __assign({ value: value, index: index }, { children: tab.component }), index)); })] }));
};
export default CommonTabs;
