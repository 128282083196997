export var colorCodes = {
    textColors: {
        black: '#212121',
        lightGray: '#707070',
        charcoal: '#303030',
        Iridium: '#404040',
        woodSmock: '#131416',
        midNightMoss: '#000',
        quillGray: '#D9D9D9',
    },
    iconBackgroundColor: {
        royalBlue: '#2A70EA',
        mirage: '#1C1B1F',
    },
    genericColors: {
        red: '#E31A1C',
        green: '#17B26A',
        grayBlue: '#4E5BA6',
    },
    backgroundColors: {
        white: '#FFF',
        sandStone: '#707070',
        alabaster: '#FAFAFA',
        black: '#000',
    },
    borderColor: {
        QuillGray: '#D4D4D4',
        Geyser: '#D9DBE9',
    },
    circle: {
        cloud: '#C4C4C4',
        pumpkinOrange: '#FF730E',
        blueGray: '#6e7b91',
    },
    cardColors: {
        borderLightGray: '#ECECEC',
    },
};
