export function getInitials(name) {
    var _a;
    if (!name)
        return '';
    var words = name.trim().split(' ');
    if (words.length === 1) {
        return ((_a = words[0][0]) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || '';
    }
    var initials = words
        .slice(0, 2)
        .map(function (word) { var _a; return ((_a = word[0]) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || ''; })
        .join('');
    sessionStorage.setItem('initials', initials);
    return initials;
}
// Utility to generate user initials
export var generateUserInitials = function (firstName, lastName) {
    return "".concat((firstName === null || firstName === void 0 ? void 0 : firstName.trim().charAt(0)) || '').concat((lastName === null || lastName === void 0 ? void 0 : lastName.trim().charAt(0)) || '');
};
