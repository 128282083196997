var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Carousel from 'react-material-ui-carousel';
var TPCarousel = function (_a) {
    var id = _a.id, children = _a.children, _b = _a.autoPlay, autoPlay = _b === void 0 ? false : _b, _c = _a.stopAutoPlayOnHover, stopAutoPlayOnHover = _c === void 0 ? false : _c, _d = _a.fullHeightHover, fullHeightHover = _d === void 0 ? false : _d, _e = _a.indicators, indicators = _e === void 0 ? true : _e, _f = _a.swipe, swipe = _f === void 0 ? true : _f, _g = _a.animation, animation = _g === void 0 ? 'slide' : _g, indicatorIconButtonProps = _a.indicatorIconButtonProps, activeIndicatorIconButtonProps = _a.activeIndicatorIconButtonProps, otherProps = _a.otherProps;
    return (_jsx(Carousel, __assign({ id: id, "test-id": id, autoPlay: autoPlay, stopAutoPlayOnHover: stopAutoPlayOnHover, fullHeightHover: fullHeightHover, indicators: indicators, swipe: swipe, animation: animation, indicatorIconButtonProps: { style: indicatorIconButtonProps }, activeIndicatorIconButtonProps: {
            style: activeIndicatorIconButtonProps,
        } }, otherProps, { children: children })));
};
export default TPCarousel;
