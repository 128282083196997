var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import router from "./RouterConfig";
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.css";
var theme = createTheme({
    typography: {
        fontFamily: '"Public Sans",sans-serif',
    },
    components: {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    borderRadius: "0",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: "".concat((window.innerWidth / 1920) * (14 / 16), "rem"),
                    color: "var(--gray-900, #101828)",
                    fontWeight: 500,
                    lineHeight: "20px",
                    zIndex: 0,
                    "& .MuiTypography-root": {
                        // Target Typography inside TableCell
                        fontSize: "inherit",
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: "".concat((window.innerWidth / 1920) * (16 / 16), "rem"),
                    color: "#707070",
                    fontWeight: 300,
                    lineHeight: "18.8px",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& .MuiSelect-select': {
                        backgroundColor: '#fff', // Customize focused text color
                    },
                },
            },
        },
    },
});
function App() {
    return (_jsx(Provider, __assign({ store: store }, { children: _jsx(PersistGate, __assign({ loading: null, persistor: persistor }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(RouterProvider, { router: router }) })) })) })) })));
}
export default App;
