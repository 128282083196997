var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import React from 'react';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { TimeApprovalProceduresStyle } from './InfoCenter.styled';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { Link } from 'react-router-dom';
import approvalProceduresPDF from '../../pdf/Tandym_Time_Expense_Approval_Process.pdf';
var TimeApprovalProcedures = function () {
    return (_jsx(TimeApprovalProceduresStyle, { children: _jsxs(Box, __assign({ className: 'banner img-1', sx: { mt: '10px' } }, { children: [_jsx(Typography, __assign({ variant: 'h2', id: 'time-approval-procedures-heading' }, { children: "Time Approval Procedures" })), _jsx(Typography, __assign({ variant: 'h6', id: 'time-approval-procedures-info' }, { children: "Here's a quick reference for Tandym's in-app and email approval process." })), _jsx(Box, __assign({ className: 'action-btns' }, { children: _jsx(Link, __assign({ to: approvalProceduresPDF, target: '_blank' }, { children: _jsxs(TPButton, __assign({ color: 'primary', sx: { fontSize: fontSizeDynamic(14) } }, { children: ["Approval Procedure", _jsx(TPSvgIcon, { resourceName: 'arrow-left-alt' })] })) })) }))] })) }));
};
export default TimeApprovalProcedures;
