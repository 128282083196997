var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import TPProgressModal from '../../shared/components/modal/TPProgressModal';
import './TimesheetsAndExpenses.scss';
import NoPageFound from '../../components/NoPageFound';
import { fetchNajaxaToken } from '../../shared/utilities/FetchNajaxatoken';
var TimesheetsAndExpenses = function (props) {
    var najaxaURL = props.najaxaURL, email = props.email, portal = props.portal, najaxaTimeSheetURL = props.najaxaTimeSheetURL;
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var _c = useState(''), token = _c[0], setToken = _c[1];
    var httpPost = useAPI().httpPost;
    var _d = useState(true), renderComponent = _d[0], setRenderComponent = _d[1];
    useEffect(function () {
        fetchNajaxaToken(httpPost, najaxaURL, email, setIsLoading, setToken, setError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [httpPost, najaxaURL, email]);
    useEffect(function () {
        if (sessionStorage.getItem('timeSheetEntitlements') === 'true') {
            setRenderComponent(true);
        }
        else {
            setRenderComponent(false);
        }
    }, [sessionStorage.getItem('timeSheetEntitlements')]);
    if (!renderComponent) {
        return _jsx(NoPageFound, {});
    }
    return (_jsx(Box, __assign({ sx: { textAlign: 'center' }, id: 'timesheets-expenses', "data-testid": 'timesheets-expenses' }, { children: isLoading ? (_jsx(TPProgressModal, { isLoading: isLoading })) : error ? (_jsx(_Fragment, { children: _jsx(Typography, __assign({ color: 'error' }, { children: error })) })) : (_jsx("div", __assign({ className: 'najaxa-iframe' }, { children: _jsx("iframe", { id: 'najaxa-iframe', src: "".concat(najaxaTimeSheetURL, "?jwt=").concat(token), title: 'Najaxa', allowFullScreen: true, className: 'najaxa-iframe-content' }) }))) })));
};
export default TimesheetsAndExpenses;
