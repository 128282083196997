var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Button, IconButton, Tooltip, } from '@mui/material';
import classnames from 'classnames';
import styles from './TPButton.module.css';
var TPButton = function (props) {
    var children = props.children, tooltip = props.tooltip, color = props.color, icon = props.icon, muiButtonProps = props.muiButtonProps, muiTooltipProps = props.muiTooltipProps, muiIconButtonProps = props.muiIconButtonProps, sx = props.sx, otherProps = __rest(props, ["children", "tooltip", "color", "icon", "muiButtonProps", "muiTooltipProps", "muiIconButtonProps", "sx"]);
    var onlyIcon = icon && !children;
    var getCssClass = function (color) {
        switch (color) {
            case 'primary':
                if (props.disabled) {
                    return styles.buttonPrimaryDisabled;
                }
                else {
                    return styles.button;
                }
            case 'secondary':
                if (props.disabled) {
                    return styles.buttonSecondaryDisabled;
                }
                else {
                    return styles.button;
                }
            default:
                return styles.button;
        }
    };
    if (onlyIcon && tooltip) {
        return (_jsx(Tooltip, __assign({}, muiTooltipProps, { title: tooltip, arrow: true, className: classnames(styles.tooltip, muiTooltipProps === null || muiTooltipProps === void 0 ? void 0 : muiTooltipProps.className) }, { children: _jsx(IconButton, __assign({}, muiIconButtonProps, props, { color: 'primary', className: classnames(styles.button, props.className), sx: __assign(__assign({}, sx), { '&:hover': __assign({}, (sx && sx['&:hover'])) }), disabled: props.disabled }, { children: icon })) })));
    }
    if (onlyIcon && !tooltip) {
        return (_jsx(IconButton, __assign({}, muiIconButtonProps, props, { color: 'primary', className: classnames(styles.button, props.className), sx: __assign(__assign({}, sx), { '&:hover': __assign({}, (sx && sx['&:hover'])) }), disabled: props.disabled }, { children: icon })));
    }
    return (_jsx(Button, __assign({}, muiButtonProps, props, { color: 'primary', className: classnames(getCssClass(props.color), props.className, styles[color]), sx: __assign(__assign({ textTransform: 'none' }, sx), { '&:hover': __assign({}, (sx && sx['&:hover'])) }), disabled: props.disabled, variant: 'contained' }, { children: children })));
};
export default TPButton;
