export var SectionTitle;
(function (SectionTitle) {
    SectionTitle["MyLiveContractors"] = "My Live Contractors";
    SectionTitle["MyOpenPositions"] = "My Open Jobs";
})(SectionTitle || (SectionTitle = {}));
export var CircleColors;
(function (CircleColors) {
    CircleColors["Corporate"] = "Corporate";
    CircleColors["PublicHealth"] = "Public Health";
    CircleColors["Technology"] = "Technology";
    CircleColors["EnterpriseApplications"] = "Enterprise Applications";
    CircleColors["Commercial"] = "Commercial";
})(CircleColors || (CircleColors = {}));
export var solutions;
(function (solutions) {
    solutions["ConsultingSolutions"] = "Consulting Solutions";
    solutions["MissionCoreSolutions"] = "Mission Core Solutions";
})(solutions || (solutions = {}));
