export var recruitmentRatios = [
    {
        title: 'Positions : Submitted',
        description: 'For every vacant position, how many were submitted to the client?',
    },
    {
        title: 'Submitted : Interviewed',
        description: 'How many candidates are submitted for each interview?',
    },
    {
        title: 'Interviewed : Offered',
        description: 'How many candidates are interviewed for each offer made?',
    },
    {
        title: 'Offered : Accepted',
        description: 'How many offers are made for each offer that is accepted?',
    },
    {
        title: 'Fill Rate %',
        description: 'Of the positions that were closed, what percentage was filled?',
    },
];
export var recruitmentTimeline = [
    {
        title: 'Search',
        description: 'Average number of days for Tandym to find suitable candidates for a job',
    },
    {
        title: 'Submit',
        description: 'Average number of days to for Tandym to shortlist suitable candidates and submit the best ones to the client',
    },
    {
        title: 'Interview',
        description: 'Average number of days for the client to Interview submitted candidates',
    },
    {
        title: 'Offer',
        description: 'Average number of days for the client to make offers to interviewed candidates',
    },
    {
        title: 'Accept',
        description: 'Average number of days for Tandym to help candidates accept the offer',
    },
    {
        title: 'Start',
        description: 'Average number of days for the client to onboard the candidate to start working',
    },
];
export var contractorsOnBilling = [
    {
        title: '12 months trend',
        description: 'A chart of the number of Tandym contractors working at the client over the past 12 months',
    },
];
export var placedPositions = [
    {
        title: '12 months trend',
        description: 'A chart of 3 KPIs for Tandym over the previous 12 months:',
        itemsList: [
            'Number of new positions opened by month',
            'Number of candidates placed by month',
            'Fill Rate % as a ratio of Number of candidates placed / Number of positions opened',
        ],
    },
];
