import { fontSizeDynamic } from "../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils";
import { colorCodes } from "../../../../tandym-web-common/src/shared/components/styles/ColorCodesList";
export var FaqsStyle = {
    headerText: {
        color: colorCodes.textColors.midNightMoss,
        fontSize: fontSizeDynamic(24),
        fontWeight: 400,
    },
    subHeading: {
        color: colorCodes.textColors.lightGray,
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
        mt: 2.5,
        ml: 1,
    },
    faqsHeader: {
        color: colorCodes.textColors.black,
        fontSize: fontSizeDynamic(20),
        fontWeight: 500,
        cursor: "pointer",
    },
    expandAllText: {
        color: colorCodes.iconBackgroundColor.royalBlue,
        fontSize: fontSizeDynamic(16),
        fontWeight: 600,
        mt: 2.5,
        cursor: "pointer",
        flexShrink: 0,
    },
    faqsBoxContainer: {
        display: "flex",
        p: 2,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    borderStyle: { borderBottom: "1px solid ".concat(colorCodes.borderColor.Geyser) },
    listItem: {
        display: "list-item",
        color: colorCodes.backgroundColors.sandStone,
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
    },
    listItemInnerTextBlue: {
        color: colorCodes.iconBackgroundColor.royalBlue,
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
        cursor: "pointer",
    },
    pointer: { cursor: "pointer" },
};
