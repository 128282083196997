var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box } from '@mui/material';
import noDataImage from '../../images/no-data-image.png';
import { FillRateBoxStyled } from './NoDataFound.styled';
var NoDataFound = function () {
    return (_jsx(FillRateBoxStyled, { children: _jsxs(Box, __assign({ sx: {
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                alignItems: 'center',
            }, className: 'no-data-container' }, { children: [_jsx("img", { src: noDataImage, alt: 'No Data Found', className: 'responsive-image' }), _jsx("span", __assign({ className: 'no-data-text1' }, { children: "No Data Available" })), _jsx("span", __assign({ className: 'no-data-text2' }, { children: "No results were found for your search." }))] })) }));
};
export default NoDataFound;
