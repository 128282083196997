import { fontSizeDynamic } from "../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils";
export var customTabsStyle = {
    active: {
        color: "#2a70ea",
        alignItems: "center",
        fontweight: 500,
        borderRadius: 0,
        backgroundColor: "#fff",
        boxshadow: "none",
        borderbottom: "1px solid #2a70ea",
        fontSize: fontSizeDynamic(16),
    },
    inActive: {
        color: "#707070",
        fontWeight: 500,
        backgroundColor: "#fff",
        boxshadow: "none",
        fontSize: fontSizeDynamic(16),
    },
};
