var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
// Function to determine the color based on the value for delta cells
var getColor = function (value, rowType) {
    if (rowType === 'deltaFromTandymAvg' && typeof value === 'number') {
        if (value > 0)
            return '#FF8A8A'; // Red for positive values
        if (value < 0)
            return '#D5ED9F'; // Green for negative values
    }
    return '#ffffff'; // Default white for other values
};
// Main component to render the recruitment timeline
var RecruitmentTimelineSteps = function (props) {
    var _a, _b;
    var recruitmentTimelineSteps = props.recruitmentTimelineSteps, clientName = props.clientName;
    var tableKeyNameValidation = function (keyName) {
        switch (keyName) {
            case 'deltaFromTandymAvg':
                return 'Delta from Tandym Avg';
            case 'tandymGTMAvg':
                return 'Tandym GTM Average';
        }
    };
    // Function for header text and background color
    var tabelHeaderTextAndBackgroundColor = function (label, header) {
        if (label === 'Total' && header === 'header')
            return '#444444';
        if (label === 'Total' && header === 'headertext')
            return 'white';
        if (['Search', 'Submit', 'Accept'].includes(label) && header === 'header')
            return '#2b6fea';
        if (['Search', 'Submit', 'Accept'].includes(label) &&
            header === 'headertext')
            return 'white';
        if (['Interview', 'Offer', 'Start'].includes(label) && header === 'header')
            return '#a6a6a6';
        if (['Interview', 'Offer', 'Start'].includes(label) &&
            header === 'headertext')
            return 'black';
        return ''; // Default
    };
    return (_jsx(_Fragment, { children: _jsxs(Box, __assign({ sx: {
                display: 'grid',
                gap: '2px',
                backgroundColor: '#000000',
                padding: '20px',
                color: '#ffffff',
            } }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, xs: 2, sx: {
                                backgroundColor: '#444444',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '60px',
                            } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: { fontWeight: 'bold', color: '#ffffff' } }, { children: "Recruitment Step" })) })), recruitmentTimelineSteps &&
                            ((_a = recruitmentTimelineSteps === null || recruitmentTimelineSteps === void 0 ? void 0 : recruitmentTimelineSteps.kpi) === null || _a === void 0 ? void 0 : _a.map(function (label, index) { return (_jsx(Grid, __assign({ item: true, xs: index === 6 ? 1.5 : 1, sx: {
                                    backgroundColor: tabelHeaderTextAndBackgroundColor(label, 'header'),
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '60px',
                                    marginRight: index === 5 ? '10px' : '5px', // Larger gap before "TOTAL"
                                } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: {
                                        fontWeight: 'bold',
                                        color: tabelHeaderTextAndBackgroundColor(label, 'headertext'),
                                    } }, { children: label })) }), index)); }))] })), recruitmentTimelineSteps &&
                    ((_b = Object.entries(recruitmentTimelineSteps)) === null || _b === void 0 ? void 0 : _b.map(function (_a, rowIndex) {
                        var key = _a[0], values = _a[1];
                        if (key === 'kpi')
                            return null; // Skip the KPI row as it's already rendered
                        return (_jsxs(React.Fragment, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, xs: 2, sx: {
                                                backgroundColor: '#000000',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '60px',
                                            } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: {
                                                    fontWeight: 'bold',
                                                    color: '#ffffff',
                                                } }, { children: key === 'selectedClient'
                                                    ? clientName
                                                    : tableKeyNameValidation(key) })) })), recruitmentTimelineSteps === null || recruitmentTimelineSteps === void 0 ? void 0 : recruitmentTimelineSteps.kpi.map(function (label, index) { return (_jsx(Grid, __assign({ item: true, xs: index === 6 ? 1.5 : 1, sx: {
                                                backgroundColor: getColor(values[label], key),
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '60px',
                                                marginRight: index === 5 ? '10px' : '5px', // Larger gap before "TOTAL"
                                            } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: { fontWeight: 'bold', color: '#000000' } }, { children: values[label] })) }), index)); })] })), key === 'Delta from Tandym Avg' && (_jsx(Box, { sx: {
                                        borderBottom: '1px solid #ffffff',
                                        marginTop: '5px',
                                        marginBottom: '22px',
                                    } }))] }, rowIndex));
                    }))] })) }));
};
export default RecruitmentTimelineSteps;
