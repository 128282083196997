var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Chip, Box, TableCell } from '@mui/material';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
var getStatusStyles = function (status) {
    switch (status) {
        case 'Pending':
            return {
                color: '#FFFFFF',
                borderColor: '#FD820B',
                backgroundColor: '#FD820B'
            };
        case 'Live':
            return {
                color: '#FFFFFF',
                borderColor: '#27B500',
                backgroundColor: '#27B500'
            };
        case 'Completed':
            return {
                color: '#FFFFFF',
                borderColor: '#2A70EA',
                backgroundColor: '#2A70EA'
            };
        default:
            return {
                color: '#FFFFFF',
                borderColor: '#d5d9eb',
                backgroundColor: '#d5d9eb'
            };
    }
};
var getIcons = function (status) {
    switch (status) {
        case 'Pending':
            return 'pending-status-icon';
        case 'Awaiting Approval':
            return 'awaiting-approval-icon';
        case 'Awaiting Onboarding':
        case 'Awaiting paperwork':
            return 'awaiting-onboarding-icon';
        case 'Onboarding Complete':
            return 'onboarding-complete-icon';
        case 'Awaiting Start':
            return 'awaiting-start-icon';
        case 'Live':
        case 'Ending Soon':
            return 'live-status-icon';
        case 'Completed':
            return 'completed-status-icon';
        case 'Terminated':
            return 'terminated-status-icon';
        default:
            return 'live-status-icon';
    }
};
var StatusCell = function (_a) {
    var status = _a.status, statusReasonGroup = _a.statusReasonGroup, id = _a.id, rest = __rest(_a, ["status", "statusReasonGroup", "id"]);
    var statusStyles = getStatusStyles(statusReasonGroup);
    return (_jsx(TableCell, __assign({ sx: { minWidth: 175 } }, rest, { children: _jsx(Chip, { id: "status-".concat(id, "-").concat(statusReasonGroup), "data-testid": "status-".concat(id, "-").concat(statusReasonGroup), label: _jsxs(Box, __assign({ sx: {
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    alignItems: 'center'
                } }, { children: [_jsx(TPSvgIcon, { resourceName: getIcons(status), id: statusReasonGroup, "data-testid": statusReasonGroup, width: 20, height: 20 }), _jsx(Box, __assign({ component: 'span', sx: { marginLeft: 1 } }, { children: status === 'Awaiting paperwork' ? 'Awaiting Onboarding' : status }))] })), variant: 'outlined', sx: {
                borderColor: statusStyles.borderColor,
                color: statusStyles.color,
                backgroundColor: statusStyles.backgroundColor,
                borderRadius: '24px',
                '&&': {
                    fontSize: "".concat(((window.innerWidth / 1920) * 14) / 16, "rem")
                }
            } }) })));
};
export default StatusCell;
